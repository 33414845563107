import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartApplication } from '../../../interface/part-application';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationService } from '../../../service/application.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html',
  styleUrl: './application-edit.component.scss'
})
export class ApplicationEditComponent implements OnInit {

  form: FormGroup;
  application: PartApplication;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { application: PartApplication },
    private dialogRef: MatDialogRef<ApplicationEditComponent>,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private layout: LayoutService,
    private snackbar: SnackbarService
  ) {
    if (this.data) {
      this.application = data.application
    }
  }

  ngOnInit() {
    this.buildForm()
  }

  buildForm() {
    if (this.application) {
      this.form = this.formBuilder.group({
        _id: this.application._id,
        description: [this.application.description, Validators.required]
      })
    } else {
      this.form = this.formBuilder.group({
        _id: "",
        description: ["", Validators.required],
      })
    }
  }


  async onSubmit() {
    const newApplication: PartApplication = this.form.getRawValue()
    const isUpdate = Boolean(newApplication._id);
    try {
      this.layout.loader = true
      if(isUpdate){
        await this.applicationService.update(newApplication)
        this.snackbar.success('APPLICATION.UPDATED');
      } else {
        const id = await this.applicationService.register(newApplication);
        newApplication._id = id;
        this.snackbar.success('APPLICATION.ADDED');
      }
      this.dialogRef.close(newApplication);
    } catch (error) {
      console.error(error)
      this.snackbar.error(isUpdate ? 'APPLICATION.NOT_UPDATED' : 'APPLICATION.NOT_ADDED', null, error);
    } finally {
      this.layout.loader = false;
    }
  }

  close() {
    this.dialogRef.close(null);
  }
}
