import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartBrand } from '../../../interface/part-brand';
import { LayoutService } from '../../../service/layout.service';
import { PartBrandService } from '../../../service/part-brand.service';
import { SnackbarService } from '../../../service/snackbar.service';

@Component({
  selector: 'app-part-brand-edit',
  templateUrl: './part-brand-edit.component.html',
  styleUrl: './part-brand-edit.component.scss'
})
export class PartBrandEditComponent implements OnInit {

  form: FormGroup;
  brand: PartBrand;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { brand: PartBrand, newBrandName: string },
    private dialogRef: MatDialogRef<PartBrandEditComponent>,
    private formBuilder: FormBuilder,
    private partBrandService: PartBrandService,
    private layout: LayoutService,
    private snackbar: SnackbarService
  ) {
    if (this.data) {
      this.brand = data.brand
    }
  }

  ngOnInit() {
    this.buildForm()
  }

  buildForm() {
    if (this.brand) {
      this.form = this.formBuilder.group({
        _id: this.brand._id,
        description: [this.brand.description, Validators.required]
      })
    } else {
      this.form = this.formBuilder.group({
        id: "",
        description: [this.data?.newBrandName  || "", Validators.required],
      })
    }
  }


  async onSubmit() {
    const newBrand: PartBrand = this.form.getRawValue()
    const isUpdate = Boolean(newBrand._id);
    try {
      this.layout.loader = true
      if (isUpdate) {
        await this.partBrandService.update(newBrand)
        this.snackbar.success('PART_BRAND.UPDATED', { brand: newBrand.description });
      } else {
        const id = await this.partBrandService.register(newBrand);
        newBrand._id = id;
        this.snackbar.success('PART_BRAND.ADDED', { brand: newBrand.description });
      }
      this.dialogRef.close(newBrand);
    } catch (error) {
      console.error(error)
      this.snackbar.error(isUpdate ? 'PART_BRAND.NOT_UPDATED' : 'PART_BRAND.NOT_ADDED', null, error);
    } finally {
      this.layout.loader = false;
    }
  }

  close() {
    this.dialogRef.close(null);
  }

}

